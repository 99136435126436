import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../../components/layout/seo';

// Layout
import Layout from '../../components/layout/layout';

// Fields
import BasicPage from '../../components/content_type/BasicPage/BasicPage';

const NodePageTemplate = ({ data }) => {
	const page = data.page.edges[0].node.r.entity_id;
	return (
		<Layout>
			<Seo title={page.title} keywords={[`gatsby`, `application`, `react`]} />
			<BasicPage data={data} />
		</Layout>
	);
};

export default NodePageTemplate;

export const query = graphql`
	query pageTemplate($slug: Int, $gid: Int) {
		page: allGroupContentLodgeGroupNodePage(
			filter: { drupal_internal__id: { eq: $slug } }
		) {
			edges {
				node {
					...groupNodePageFragment
				}
			}
		}
		files: allFileFile {
			edges {
				node {
					style: image_style_uri {
						...imageStyleFragment
					}
					drupal_id
				}
			}
		}
		lodge: groupLodge(drupal_internal__id: { eq: $gid }) {
			...lodgeFragment
		}
	}
`;
